import * as React from 'react';
import { Text, MainContainer, SEO } from '~/components';
import { css } from '@emotion/react';

const content = (theme) => ({
  ...theme.fonts.futura,
  color: theme.colors.sand,
  'ol li': {
    fontSize: '1.2rem',
    paddingTop: theme.spacing(3),
  },
});

function Legal(props) {
  const { pageContext } = props;
  const { title, body } = pageContext;
  return (
    <>
      <SEO title={title} />
      <MainContainer css={(theme) => ({ fontColor: theme.colors.sand })}>
        <h1
          css={(theme) => ({
            color: theme.colors.rose,
            textTransform: 'uppercase',
            textAlign: 'center',
            paddingTop: theme.spacing(8),
          })}
        >
          {title}
        </h1>

        <div css={content} dangerouslySetInnerHTML={{ __html: body }} />
      </MainContainer>
    </>
  );
}

export default Legal;
